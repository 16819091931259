import {TradeParams} from "./common";
import {BigNumber} from "ethers";
import {bnToDecimal, prepareFormNumber} from "../../utils";
import {FormError, FormErrors, MarketTradeLimiterParams, RiskDirection} from "../../types";
import bn from "bignumber.js";
import Decimal from "decimal.js";

export const getFormError = (
  params: TradeParams,
  tradeLimiterParams: MarketTradeLimiterParams[]
): FormError | null => {
  const {
    futureId,
    underlyingName,
    underlyingBalance,
    underlyingDecimals,
    market,
    marketId,
    formValues,
    tradeQuote,
    quoteError,
    isQuoteSwapFetching,
    nativeTokenBalance
  } = params

  const zero = BigNumber.from(0)
  const currentFuture = market
    ? market.futures.find((item) => item.id === futureId)
    : undefined
  const marketTradeLimiterParams = tradeLimiterParams.find(item => item.marketId === marketId)?.params

  const notional = prepareFormNumber(formValues.notional, underlyingDecimals)
  const collateral = prepareFormNumber(formValues.collateral, underlyingDecimals)

  if(formValues.isNativeTokenSelected && nativeTokenBalance.sub(collateral).isNegative()) {
    return {
      field: 'margin',
      type: FormErrors.insufficientFunds,
      value: zero,
      text: 'Insufficient funds'
    }
  }

  if(!formValues.isNativeTokenSelected && underlyingBalance.sub(collateral).isNegative()) {
    return {
      field: 'margin',
      type: FormErrors.insufficientFunds,
      value: zero,
      text: 'Insufficient funds'
    }
  }

  if(currentFuture) {
    if(!formValues.isMaxRateLimitAuto) {
      const { currentFutureRate } = currentFuture.vAMMParams
      const lastRate = new bn(currentFutureRate.toString())
        .div(Math.pow(10, 16))
        .dp(2)
        .toNumber()
      const rateLimit = +(formValues.maxRateLimit || 0)
      if((formValues.riskDirection === RiskDirection.receiver && rateLimit >= lastRate) ||
        (formValues.riskDirection === RiskDirection.payer && rateLimit <= lastRate)
      ) {
        return {
          field: 'maxRateLimit',
          type: FormErrors.exceededRateLimit,
          text: `Rate limit should be ${formValues.riskDirection === RiskDirection.receiver ? '<' : '>'} ${lastRate}%`,
        }
      }
    }
  }

  if(tradeQuote) {
    const riskDirection = formValues.riskDirection
    const {
      insufficientLiquidityForPayer,
      insufficientLiquidityForReceiver,
      exceededTradeRateImpactLimitForPayer,
      exceededTradeRateImpactLimitForReceiver,
      exceededTradeNotionalLimitForPayer,
      exceededTradeNotionalLimitForReceiver,
      exceededMarketRateImpactLimitForPayer,
      exceededMarketRateImpactLimitForReceiver
    } = tradeQuote
    // const directionAlias = riskDirection === RiskDirection.receiver ? 'short' : 'long'

    // TODO: add exact limit value from protocol config
    if(marketTradeLimiterParams) {
      if(
        (insufficientLiquidityForPayer && riskDirection === RiskDirection.payer)
        || (insufficientLiquidityForReceiver && riskDirection === RiskDirection.receiver)
      ) {
        return {
          field: 'notional',
          type: FormErrors.insufficientLiquidity,
          value: zero,
          text: `Insufficient liquidity. Please reduce trade size.`,
          tooltipText: 'Insufficient liquidity. Please reduce trade size.'
        }
      } else if(
        (exceededTradeRateImpactLimitForPayer && riskDirection === RiskDirection.payer)
        || (exceededTradeRateImpactLimitForReceiver && riskDirection === RiskDirection.receiver)
      ) {
        const value = new Decimal((market?.riskParameters.maxRateImpactPerTrade || zero).toString())
          .div(10 ** 16)
          .toDecimalPlaces(2)
        return {
          field: 'notional',
          type: FormErrors.exceededTradeRateImpactLimit,
          value: zero,
          text: `Price impact exceeds ${value}%`,
          tooltipText: `Price impact exceeds ${value}%. Reduce trade size.`,
        }
      } else if(
        (exceededMarketRateImpactLimitForPayer && riskDirection === RiskDirection.payer)
        || (exceededMarketRateImpactLimitForReceiver && riskDirection === RiskDirection.receiver)
      ) {
        const value = new Decimal((marketTradeLimiterParams.marketRateChangeLimit || zero).toString())
          .div(10 ** 16)
          .toDecimalPlaces(2)
        return {
          field: 'notional',
          type: FormErrors.exceededMarketRateImpactLimit,
          value: zero,
          text: `24 hours price shift exceeds ${value}%`,
          tooltipText: `24 hours price shift exceeds ${value}%. Please reduce trade size, or try later.`
        }
      } else if(
        (exceededTradeNotionalLimitForPayer && riskDirection === RiskDirection.payer)
        || (exceededTradeNotionalLimitForReceiver && riskDirection === RiskDirection.receiver)
      ) {
        const value = bnToDecimal((marketTradeLimiterParams.maxTradeNotional || zero), underlyingDecimals)
        return {
          field: 'notional',
          type: FormErrors.exceededNotionalLimit,
          value: zero,
          text: `Notional exceeds ${value} ${underlyingName}. Please reduce trade size.`,
          tooltipText: `Notional exceeds limit. Please reduce trade size.`,
        }
      }
    }
  }

  // TODO: add check visible on the new UI
  // if(tradeQuote && selectedMarginThreshold) {
  //   const totalMargin = newMargin ? marginTotal(newMargin) : BigNumber.from(0)
  //   if(totalMargin.add(collateral).sub(selectedMarginThreshold).isNegative()) {
  //     const additionalCollateral = selectedMarginThreshold.sub(collateral).sub(totalMargin)
  //     if(additionalCollateral.gt(zero)) {
  //       const text = `
  //         Additional deposit required:
  //         ${bn(fromBn(additionalCollateral, underlyingDecimals)).dp(2, bn.ROUND_UP).toString()}
  //         ${underlyingName}
  //       `
  //       return {
  //         field: 'margin',
  //         type: FormErrors.collateralRequired,
  //         value: additionalCollateral,
  //         text
  //       }
  //     }
  //   }
  // }

  if(quoteError) {
    const message = (quoteError as Error).message
    let errorName = ''
    if(message && message.includes('Error')) {
      const [_, description] = message.split('Error:')
      if(description) {
        const [errorTitle] = description.split('\n')
        if(errorTitle) {
          errorName = errorTitle.trim()
        }
      }
    }
    if(!errorName) {
      errorName = message
    }
    if(errorName) {
      const type = errorName.toLowerCase().includes(FormErrors.floatTokenAmountIsTooSmall.toLowerCase())
        ? FormErrors.floatTokenAmountIsTooSmall
        : FormErrors.common
      return {
        type,
        field: 'notional',
        text: type === FormErrors.floatTokenAmountIsTooSmall ? 'Insufficient notional amount' : 'Trade quote error'
      }
    }
  }

  if(notional.gt(zero) && quoteError && !isQuoteSwapFetching) {
    return {
      type: FormErrors.quoteSwapError,
      field: 'notional',
      text: 'Trade quote unavailable',
    }
  }

  return null
}
