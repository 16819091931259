import React from 'react'
import {Box, Table, TableBody, TableCell, TableHeader, TableRow, Text} from "grommet";
import styled from "styled-components";
import {IRateMathType, RiskDirection, RiskDirectionType} from "../../../../types";
import {DynamicValue, MaturityBadge, QuestionMark, RightArrow, ValuesChangeBadge} from "../../../../components";
import tooltips from "../../../../utils/tooltips";
import {marginTotal} from "../../../../utils/mappers";
import {BigNumber} from "ethers";
import {TradeProps} from "../../common";
import {bnToDecimal, getFutureAlias, numberToBN, prepareFormNumber} from "../../../../utils";
import {useAccount} from "wagmi";
import {useNavigate} from "react-router-dom";
import {RiskDirectionTag} from "../../../../components/tag";
import {CompoundingRateMath, LinearRateMath} from "../../../../utils/leverage";
import {useProtocolData} from "../../../../providers/ProtocolDataProvider";
import {Skeleton} from "antd";
import {OpenPosition} from "../../../../api/dataService";
import {toBn} from "evm-bn";
import {configMode} from "../../../../config";

const TableContainer = styled(Table)`
    thead {
      th {
        color: #838495;
        font-size: 14px;
        font-weight: 500;
        border: none;
      }
    }

    tr {
      border-top: 0!important;
    }

    td:first-child, th:first-child {
      border-top: 0 !important;
      padding: 0;
    }

    td {
        border-top: 1px dashed ${
            props => props.theme.global.colors.tableRowBorder
        }!important;
    }

    th:last-child, td:last-child {
        padding-right: 0;
    }
`

const TableMarkerCell = styled(TableCell)<{ isActive: boolean }>`
    padding: 0 !important;
    ${props => props.isActive &&
      `
        border-left: 3px solid ${props.theme.global.colors.brandRho};
      `
    }
`

export const OpenPositionsList = (props: TradeProps & {
  openPositions: OpenPosition[];
}) => {
  const { openPositions, formValues } = props

  const navigate = useNavigate()
  const { isConnected } = useAccount()
  const { portfolio, userPositions, markets } = useProtocolData()

  const futures = markets.flatMap(item => item.futures)

  return <Box>
    <TableContainer>
      <TableHeader style={{ height: '36px' }}>
        <TableRow>
          <TableCell size={'16px'} scope="col" />
          <TableCell scope="col">
            <Box direction={'row'} align={'center'} gap={'2px'}>
              <Text size={'12px'} color={'textSecondary'}>MARKET</Text>
              <QuestionMark tooltipId={'open_market'} tooltipText={'Market name'} />
            </Box>
          </TableCell>
          <TableCell scope="col">
            <Box direction={'row'} align={'center'} gap={'2px'}>
              <Text size={'12px'} color={'textSecondary'}>MATURITY</Text>
              <QuestionMark tooltipId={'open_maturity'} tooltipText={tooltips.maturity} />
            </Box>
          </TableCell>
          <TableCell scope="col">
            <Box direction={'row'} align={'center'} gap={'2px'}>
              <Text size={'12px'} color={'textSecondary'}>DIRECTION</Text>
              <QuestionMark tooltipId={'open_rd'} tooltipText={tooltips.riskDirection} />
            </Box>
          </TableCell>
          <TableCell scope="col">
            <Box direction={'row'} align={'center'} gap={'2px'}>
              <Text size={'12px'} color={'textSecondary'}>NOTIONAL</Text>
              <QuestionMark tooltipId={'open_notional'} tooltipText={tooltips.notional} />
            </Box>
          </TableCell>
          <TableCell scope="col">
            <Box direction={'row'} align={'center'} gap={'2px'}>
              <Text size={'12px'} color={'textSecondary'}>LEVERAGE</Text>
              <QuestionMark tooltipId={'open_leverage'} tooltipText={tooltips.futureLeverage} />
            </Box>
          </TableCell>
          <TableCell scope="col">
            <Box direction={'row'} align={'center'} gap={'2px'}>
              <Text size={'12px'} color={'textSecondary'}>DV01</Text>
              <QuestionMark tooltipId={'open_dv01'} tooltipText={tooltips.dv01} />
            </Box>
          </TableCell>
          <TableCell scope="col">
            <Box direction={'row'} align={'center'} gap={'2px'}>
              <Text size={'12px'} color={'textSecondary'}>P&L</Text>
              <QuestionMark tooltipId={'open_pl'} tooltipText={tooltips.profitAndLoss} />
            </Box>
          </TableCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {openPositions.map((item, index) => {
          const { futureId, direction: riskDirection } = item

          const future = futures.find((item) => item.id === futureId)
          const market = markets.find(item => item.descriptor.id === future?.marketId)
          const portfolioItem = portfolio.find(item => item.descriptor.id === future?.marketId)
          const marketName = market ? `${market?.descriptor.sourceName} ${market?.descriptor.instrumentName.split(' ')[0]}` : ''
          const maturityTimestamp = future ? (+future.termStart.toString() + +future.termLength.toString()) * 1000 : 0
          const isActive = item.futureId === props.futureId
          const zero = BigNumber.from(0)
          const underlyingDecimals = market?.descriptor.underlyingDecimals || 6

          const notional = BigNumber.from(item.notional)
          const dv01 = toBn(item.dv01.toString(), underlyingDecimals)

          const profitAndLoss = numberToBN(item.position.profitAndLoss, underlyingDecimals)

          let nextRiskDirection: null | RiskDirectionType = riskDirection
          let nextNotional: null | BigNumber = null
          let nextDv01 = null

          const marginFrom = portfolioItem
            ? marginTotal(portfolioItem.marginState.margin)
            : zero
          let leverageTo: number | null = null

          const underlyingName = market?.descriptor.underlyingName || 'N/A'

          if(isActive) {
            const userPosition = userPositions.find(item => item.futureId === futureId)
            if(userPosition?.openPosition.notional) {

              const { openPosition } = userPosition

              let notional = BigNumber.from(openPosition.notional)
              if(openPosition.direction === RiskDirectionType.RECEIVER) {
                notional = notional.mul(-1)
              }
              let formNotional = prepareFormNumber(formValues.notional, underlyingDecimals)
              if (formValues.riskDirection === RiskDirection.receiver) {
                formNotional = formNotional.mul(-1)
              }
              const resultNotional = formNotional.add(notional)
              nextRiskDirection = resultNotional.eq(0) ? null
                : resultNotional.lt(0)
                  ? RiskDirectionType.RECEIVER : RiskDirectionType.PAYER
              nextNotional = resultNotional.abs()

              leverageTo = +Math.abs(props.leverage).toFixed(1)

              if(market && future) {
                const rateMath = market.descriptor.rateMathType === IRateMathType.LINEAR
                  ? new LinearRateMath()
                  : new CompoundingRateMath()
                const nextDv01Decimal = rateMath.getDv01(
                  bnToDecimal(resultNotional, underlyingDecimals),
                  bnToDecimal(future.vAMMParams.currentFutureRate, 18),
                  future.termStart.add(future.termLength).sub(Math.round(Date.now()/1000)).toNumber()
                ).abs()
                nextDv01 = prepareFormNumber(nextDv01Decimal.toString(), underlyingDecimals)
              }
            }
          }

          let leverageFrom = marginFrom.gt(0)
            ? +notional.mul(100).div(marginFrom).toString()
            : 1
          leverageFrom = +(leverageFrom / 100).toFixed(1)

          const rowFutureAlias = market && future ? getFutureAlias(market, future) : ''
          const currentFutureAlias = props.market && props.future ? getFutureAlias(props.market, props.future) : ''

          const onSelectFuture = () => {
            if(rowFutureAlias !== currentFutureAlias) {
              navigate(`/trade/${rowFutureAlias}?network=${configMode}`)
            }
          }

          return <TableRow
            key={item.futureId + index}
            onClick={rowFutureAlias !== currentFutureAlias ? onSelectFuture : undefined}
            style={{
              borderTop: '1px solid #383D57',
              cursor: rowFutureAlias !== currentFutureAlias ? 'pointer' : 'default'
            }}
          >
            <TableMarkerCell size={'16px'}  isActive={isActive} />
            <TableCell size={'120px'}>
              <Box>
                <Text>{marketName}</Text>
              </Box>
            </TableCell>
            <TableCell size={'90px'}>
              <Box>
                <MaturityBadge
                  value={maturityTimestamp}
                  showDaysLeft={false}
                />
              </Box>
            </TableCell>
            <TableCell size={'100px'}>
              <Box direction={'row'}>
                {riskDirection === nextRiskDirection &&
                   <RiskDirectionTag direction={riskDirection} />
                }
                {riskDirection !== nextRiskDirection &&
                  <Box direction={'row'} align={'center'} gap={'4px'}>
                      <RiskDirectionTag direction={riskDirection} />
                      <RightArrow fill={'gray'} />
                      <RiskDirectionTag direction={nextRiskDirection} />
                  </Box>
                }
              </Box>
            </TableCell>
            <TableCell size={'200px'}>
              <ValuesChangeBadge
                from={notional}
                to={nextNotional}
                decimals={underlyingDecimals}
                name={underlyingName}
                showName={true}
              />
            </TableCell>
            <TableCell size={'80px'}>
              <Box direction={'row'} align={'center'} gap={'2px'}>
                <Text>{isConnected ? `${leverageFrom.toString()}x` : '-'}</Text>
                {leverageTo !== null && leverageTo !== leverageFrom &&
                    <Box direction={'row'} align={'center'}>
                        <RightArrow isPositive={leverageFrom < leverageTo} />
                    </Box>
                }
                {leverageTo !== null && leverageTo !== leverageFrom &&
                    <Text>{leverageTo.toString()}x</Text>
                }
              </Box>
            </TableCell>
            <TableCell size={'180px'}>
              <ValuesChangeBadge
                from={dv01}
                to={!notional.isZero() ? nextDv01 : null}
                decimals={underlyingDecimals}
                name={underlyingName}
                showName={true}
              />
            </TableCell>
            <TableCell>
              <DynamicValue value={profitAndLoss} decimals={underlyingDecimals} name={underlyingName} />
            </TableCell>
            {/*<TableCell pad={{ right: '22px', left: '22px' }}>*/}
            {/*  {!isMatured &&*/}
            {/*      <Box direction={'row'} justify={'end'}>*/}
            {/*          <Button disabled={true} style={{*/}
            {/*            height: '25px',*/}
            {/*            padding: '2px 10px',*/}
            {/*            border: 'none'*/}
            {/*          }}>Close</Button>*/}
            {/*      </Box>*/}
            {/*  }*/}
            {/*</TableCell>*/}
          </TableRow>
        })}
      </TableBody>
    </TableContainer>
  </Box>
}
