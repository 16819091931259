import React from 'react'
import {Box, Table, TableBody, TableCell, TableHeader, TableRow, Text} from "grommet";
import styled from "styled-components";
import {FutureOpenPosition, RiskDirection, RiskDirectionType} from "../../../../types";
import {DynamicValue, Number, QuestionMark} from "../../../../components";
import tooltips from "../../../../utils/tooltips";
import {marginTotal} from "../../../../utils/mappers";
import {BigNumber} from "ethers";
import {bnToDecimal, getFutureAlias, getLiquidationRate, getShortDate, numberToBN} from "../../../../utils";
import {useAccount} from "wagmi";
import {useNavigate} from "react-router-dom";
import {RiskDirectionTag} from "../../../../components/tag";
import {useProtocolData} from "../../../../providers/ProtocolDataProvider";
import { Decimal } from 'decimal.js'
import {ClosedPosition, OpenPosition} from "../../../../api/dataService";
import {SelectMarket} from "../../../../components/market-select";
import {ViewType} from "../../../../app.types";

const TableContainer = styled(Table)`
    thead {
      th {
        color: #838495;
        font-size: 14px;
        font-weight: 500;
        border: none;
      }
    }

    tr {
      border-top: 0!important;
    }

    //td:first-child, th:first-child {
    //  border-top: 0 !important;
    //  padding: 0;
    //}

    td {
        border-top: 1px dashed ${
  props => props.theme.global.colors.tableRowBorder
}!important;
    }

    th:last-child, td:last-child {
        padding-right: 0;
    }
`

const TableMarkerCell = styled(TableCell)<{ isActive: boolean }>`
    padding: 0 !important;
    ${props => props.isActive &&
  `
        border-left: 3px solid ${props.theme.global.colors.brandRho};
      `
}
`

export const ClosedPositions = (props: {
  viewType: ViewType
  market?: SelectMarket
  positions: ClosedPosition[]
  isActivePositions?: boolean
}) => {
  const navigate = useNavigate()

  const { viewType, positions } = props

  const {
    markets,
    portfolio,
  } = useProtocolData()

  const futures = portfolio.flatMap(item => item.futures)

  return <Box style={{ overflowX: viewType === 'mobile' ? 'auto' : 'unset' }}>
    <TableContainer>
      <TableHeader style={{ height: '36px' }}>
        <TableRow>
          {/*<TableCell size={'16px'} scope="col" />*/}
          <TableCell scope="col">
            <Box direction={'row'} align={'center'} gap={'2px'}>
              <Text size={'12px'} color={'textSecondary'}>INSTRUMENT/RATE</Text>
              <QuestionMark tooltipId={'instrument_tooltip'} tooltipText={tooltips.instrument} />
            </Box>
          </TableCell>
          <TableCell scope="col">
            <Box direction={'row'} align={'center'} gap={'2px'}>
              <Text size={'12px'} color={'textSecondary'}>P&L (USDT)</Text>
              <QuestionMark tooltipId={'pnl_tooltip'} tooltipText={tooltips.profitAndLoss} />
            </Box>
          </TableCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {positions.map((item, index) => {
          const { futureId, maturity, lastRate } = item

          const future = futures.find((item) => item.id === futureId)
          const market = markets.find(item => item.descriptor.id === future?.marketId)
          const underlyingDecimals = market?.descriptor.underlyingDecimals || 6
          const underlyingName = market?.descriptor.underlyingName || ''

          const futureRate = future
            ? new Decimal(lastRate).div(10 ** 16).toDecimalPlaces(2)
            : new Decimal(0).toDecimalPlaces(2)
          const rowFutureAlias = market && future ? getFutureAlias(market, future) : ''
          const currentFutureAlias = ''

          const onSelectFuture = () => {
            if(rowFutureAlias !== currentFutureAlias) {
              navigate(`/trade/${rowFutureAlias}`)
            }
          }

          const instrumentInfo = props.market === 'all' && market && future
            ? `${getFutureAlias(market, future)} (${futureRate}%)`
            : `${getShortDate(maturity * 1000)} (${futureRate}%)`

          return <TableRow
            key={item.futureId + index}
            // onClick={rowFutureAlias !== currentFutureAlias ? onSelectFuture : undefined}
            style={{
              borderTop: '1px solid #383D57',
              cursor: rowFutureAlias !== currentFutureAlias ? 'pointer' : 'default'
            }}
          >
            {/*<TableMarkerCell size={'16px'} isActive={isActive} />*/}
            <TableCell size={'250px'}>
              <Box>
                <Text>{instrumentInfo}</Text>
              </Box>
            </TableCell>
            <TableCell>
              <Box width={'50px'}>
                <Number value={numberToBN(item.profitAndLoss, underlyingDecimals)} decimals={underlyingDecimals} name={underlyingName} />
              </Box>
            </TableCell>
          </TableRow>
        })}
      </TableBody>
    </TableContainer>
  </Box>
}
